@import 'variables';

:root {
    gp-angular-error {
        &.template {
            display: flex;
            flex-direction: column;
        }
    }

    .gp-group {
        background-color: #fff;
        border: 1px solid var(--color-grey);
        border-radius: var(--radius);

        button {
            &:first-of-type {
                border-radius: var(--radius) 0 0 var(--radius);
            }

            &:not(:first-of-type):not(:last-of-type) {
                border-radius: 0;
            }

            &:last-of-type {
                border-radius: 0 var(--radius) var(--radius) 0;
            }
        }
    }

    .gp-classic-paginator {
        @media #{$small-and-down} {
            flex-direction: column;
            padding-top: var(--size-default);
        }

        .extra-label {
            font-size: 75%;
            color: rgba(0, 0, 0, 0.54);

            ~ mat-paginator {
                margin-left: 0;
            }

        }
    }

    .gp-role-chip {
        .mat-mdc-chip.mat-mdc-standard-chip {
            padding: 0 var(--size-small);
            height: 24px;
            min-height: 24px;

            &.accent-50 {
                background-color: mat-color($accent, 50);
            }

            &.primary-100 {
                background-color: mat-color($primary, 100);
            }
        }
    }
}
