@import 'variables';

:root {
    mat-sidenav-content {
        gp-angular-logo {
            max-width: 9.5rem;

            img, svg {
                max-width: 18rem;
            }
        }
    }

    .template {
        position: absolute;
        display: block;
        background-color: mat-color($primary, '500-contrast');
        width: 100%;
        height: 100%;

        > mat-sidenav-container {
            height: 100%;

            > .mat-drawer-backdrop.mat-drawer-shown {
                background-color: rgba(255, 255, 255, .6);
            }

            > mat-sidenav {
                width: 100%;
                max-width: var(--sidenav-width);

                &[color='primary'] {
                    background-color: mat-color($primary, 900);
                    color: mat-color($primary, '900-contrast');
                    border-right-width: 0;

                    .mat-mdc-list-item {
                        &.header-group {
                            .mdc-list-item__content {
                                font-size: 75%;
                                padding-top: var(--size-default);
                                padding-bottom: var(--size-small);
                                cursor: default;
                            }
                        }

                        &:hover, &:focus {
                            .mdc-list-item__primary-text {
                                color: inherit;
                            }
                        }

                        &:not(.header-group)::before {
                            background: none;
                        }

                        &.active-list-item, &.active-group {
                            background-color: mat-color($primary, 600);
                        }

                        &.active-list-item {
                            border-left-color: var(--color-highlight);
                        }

                        &:not(.active-list-item):not(.active-group):not(.header-group) {
                            color: rgba(mat-color($primary, '600-contrast'), .4);

                        }

                        .mdc-list-item__primary-text {
                            display: flex;
                        }
                    }

                    .version {
                        color: rgba(mat-color($primary, '600-contrast'), .6);
                    }
                }

                mat-nav-list {
                    width: 100%;
                    padding-top: 0;

                    .mat-mdc-list-item {
                        &.active-list-item {
                            border-left: 4px solid transparent;
                            padding-left: calc(var(--size-default) - 4px);
                            border-radius: 0;
                        }
                    }

                    &.submenu {
                        .mat-mdc-list-item {

                            background: rgba(mat-color($primary, 800), .85);
                            padding-left: calc(var(--size-default) * 3);

                            &.active-list-item {

                                padding-left: calc(var(--size-default) * 3 - 4px);

                            }
                        }
                    }
                }

                gp-angular-logo {
                    max-width: 160px;
                    margin: 0;
                }

                .version {
                    font-size: 10px;
                    overflow: visible;
                }
            }

            > mat-sidenav-content {
                display: flex;
                flex-direction: column;
            }
        }

        &-header,
        &-footer {
            width: 100%;
            min-height: 64px;
        }

        &-header {
            position: sticky;
            box-shadow: 0 0 7px rgba(0, 0, 0, .5);
            top: 0;
            z-index: 2;

            gp-angular-logo {
                max-width: 9.5rem;
            }

            button {
                &.user {
                    text-transform: initial;
                    max-width: 15rem;

                    @extend .flex-center;

                    .text-trim {
                        max-width: calc(100% - (var(--size-medium) * 2));
                    }
                }
            }
        }

        &-footer {
            @extend %template-footer;
        }

        &-header-attachment {
            position: absolute;
            display: flex;
            height: var(--header-height);
            padding: 0 var(--size-default);
            top: 0;
            right: calc(15rem + (var(--size-default) * 2));
            left: 0;
            z-index: 2;

            @media #{$small-and-down} {
                &.skip-mobile {
                    position: sticky;
                    background-color: mat-color($primary, 50);
                    box-shadow: 0 0 7px rgb(0 0 0 / 50%);
                    top: 0;
                    right: 0;
                    z-index: 2;
                }

                &:not(.skip-mobile) {
                    min-width: 40px;
                    overflow: hidden;
                    right: 0;
                    left: auto;

                    .search-wrapper {
                        form {
                            .mat-mdc-form-field {
                                &.mat-focused {
                                    width: calc(100vw - (var(--size-default) * 2));

                                    .mdc-text-field--outlined {
                                        opacity: 1;
                                    }
                                }

                                &:not(.mat-focused) {
                                    width: 0;

                                    .mdc-text-field--outlined {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .search-wrapper {
                position: relative;
                display: flex;
                flex-grow: 1;
                align-items: center;
                max-width: 400px;

                form {
                    position: relative;
                    display: flex;
                    flex-grow: 1;
                    font-size: initial;
                    max-height: var(--header-height);
                    line-height: 1.75;

                    @extend %heal-search-small;

                    .mat-mdc-form-field {
                        width: 100%;
                    }
                }
            }

            .mat-badge-content {
                top: calc(50% - 22px);
                right: calc(var(--size-default) * -1);
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: auto;
        }
    }

    @media #{$large-and-up} {
        .row.split {
            display: flex;

            .col {
                &.border-right-L, &.border-left-L {
                    min-height: 100%;
                }
            }
        }
    }
}
