:root {
    [formGroupName="passwordGroup"] mat-form-field,
    mat-form-field.auto-hint,
    mat-form-field.auto-error {
        .mat-mdc-form-field-hint-wrapper {
            position: relative;
            display: inline-flex;
            margin: 0 var(--size-small);
        }
        .auto-error,
        .auto-hint {
            white-space: normal !important;
        }
    }

    .auto-hint,
    .auto-error {
        auto-error,
        auto-hint,
        .auto {
            white-space: normal !important;
        }
    }

    mat-error, mat-hint {
        font-size: 10px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.125;

        &.text-wrap {
            white-space: normal;
        }

        &:hover {
            cursor: default;
            //opacity: .5;
        }
    }

    .cdk-overlay-container {
        .error {
            background-color: mat-color($warn);
            //min-width: calc(100% + 32px);
            //margin: 0 0 -16px -16px;
        }
    }
}
