:root {
    mat-progress-bar {
        &.blur-below ~ *:not(.mat-toolbar) {
            @include blur(2px);
            pointer-events: none;
        }

        &.loader {
            @extend %heal-new-loader;
        }

        &.absolute {
            position: absolute;
            z-index: 2;
        }

        &.hidden {
            position: absolute;
            visibility: hidden;
        }

        &.small {
            height: 2px;
        }
    }
}
