:root {
    mat-form-field {
        margin: .25em 0;
        line-height: 1.125;

        &.time {
            width: 6em;

            .mdc-line-ripple::before, .mdc-line-ripple::after {
                display: unset;
            }
        }

        .mat-mdc-form-field-flex {
            padding: 0 .75em 0 .75em;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background: inherit;
        }

        .mat-mdc-form-field-focus-overlay {
            opacity: 0 !important;
        }

        .mat-mdc-text-field-wrapper {
            padding: 0;
        }

        .mat-mdc-form-field-hint-wrapper {
            padding: 0;
        }

        .mdc-line-ripple::before, .mdc-line-ripple::after {
            display: none;
        }
    }

    mat-label.as-mat-form-field-label {
        display: block;
        font-size: 87.5%;
    }

    .date-picker-overlay {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;

        ~ input, ~ mat-date-range-input {
            visibility: hidden;
        }

        &.disabled {
            color: rgba(0, 0, 0, 0.26);
        }
    }

    .files {
        border: 1px solid rgba(var(--color-grey-rgb), 1);
        border-radius: 4px;
        height: 48px;
        align-content: center;
        margin-top: .25em;
        margin-bottom: 1.34375em;

        input {
            cursor: pointer;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            @include opacity(0);
        }

        &.upload {
            border-style: dashed;
            border-color: rgba(0, 0, 0, .12); // as input border
        }

        .file-list {
            display: flex;
            align-items: center;
            justify-content: space-between;// space-evenly;
            cursor: pointer;

            //span {
            //    white-space: nowrap;
            //    text-overflow: ellipsis;
            //    overflow: hidden;
            //    display: block;
            //}

            > * {
                display: flex;
                align-self: center;
            }

            .mat-mdc-icon-button.mat-icon-button {
                height: auto;
                width: auto;
                line-height: initial;
            }
        }

        .file-upload {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            z-index: 0;
        }
    }

    .as-mat-form-field {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(16px * 4.5);
        padding-top: var(--size-small);
    }

    .mat-mdc-option {
        &.checkbox-hide {
            .mat-pseudo-checkbox {
                display: none;
                opacity: 0;
            }
        }

        &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
            background: rgba(0, 0, 0, 0.12);
        }
    }

    .read-only {
        input {
            color: rgba(var(--color-dark-rgb), .38);
        }
    }

    .mdc-checkbox {
        --mdc-checkbox-state-layer-size: var(--size-default);

        .mdc-checkbox__checkmark-path {
            stroke: white;
        }
    }

    .mat-icon {
        overflow: unset;
    }

    .mat-label-with-icon {
        .mdc-notched-outline__notch {
            width: inherit;

            label {
                width: 100%;

                mat-label {
                    width: calc(100% - 40px);
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
