// Grid
$num-cols: 12 !default;
//$gutter-width: 1.5rem !default;
$gutter-width: 16px !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;

.col .row {
    margin-left: (-1 * $gutter-width / 2);
    margin-right: (-1 * $gutter-width / 2);
}

// Mixins to eliminate code repitition
@mixin reset-offset {
    margin-left: auto;
    left: auto;
    right: auto;
}

@mixin grid-classes($size, $i, $perc) {
    &.offset-#{$size}#{$i} {
        margin-left: $perc;
    }
    &.pull-#{$size}#{$i} {
        right: $perc;
    }
    &.push-#{$size}#{$i} {
        left: $perc;
    }
}


.row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    // Clear floating children
    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .col {
        float: left;
        box-sizing: border-box;
        padding: 0 $gutter-width / 2;
        min-height: 1px;

        &[class*="push-"],
        &[class*="pull-"] {
            position: relative;
        }

        &.no-padding {
            padding-right: 0;
            padding-left: 0;
        }

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            &.s#{$i} {
                width: $perc;
                @include reset-offset;
            }
            $i: $i + 1;
        }

        $i: 1;
        @while $i <= $num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("s", $i, $perc);
            $i: $i + 1;
        }

        @media #{$medium-and-up} {

            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                &.m#{$i} {
                    width: $perc;
                    @include reset-offset;
                }
                $i: $i + 1
            }

            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                @include grid-classes("m", $i, $perc);
                $i: $i + 1;
            }
        }

        @media #{$large-and-up} {

            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                &.l#{$i} {
                    width: $perc;
                    @include reset-offset;
                }
                $i: $i + 1;
            }

            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                @include grid-classes("l", $i, $perc);
                $i: $i + 1;
            }
        }

        @media #{$extra-large-and-up} {

            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                &.xl#{$i} {
                    width: $perc;
                    @include reset-offset;
                }
                $i: $i + 1;
            }

            $i: 1;
            @while $i <= $num-cols {
                $perc: unquote((100 / ($num-cols / $i)) + "%");
                @include grid-classes("xl", $i, $perc);
                $i: $i + 1;
            }
        }
    }
}

/*********************
  Media Query Classes
**********************/
@media only screen and (max-width: 600px) {
    .hide-on-small-only, .hide-on-small-and-down {
        display: none !important;
    }
}

@media only screen and (max-width: 992px) {
    .hide-on-med-and-down {
        display: none !important;
    }
}

@media only screen and (min-width: 601px) {
    .hide-on-med-and-up {
        display: none !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
    .hide-on-med-only {
        display: none !important;
    }
}

@media only screen and (min-width: 993px) {
    .hide-on-large-only {
        display: none !important;
    }
}

@media only screen and (min-width: 1201px) {
    .hide-on-extra-large-only {
        display: none !important;
    }
}

@media only screen and (min-width: 1201px) {
    .show-on-extra-large {
        display: block !important;
    }
}

@media only screen and (min-width: 993px) {
    .show-on-large {
        display: block !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
    .show-on-medium {
        display: block !important;
    }
}

@media only screen and (max-width: 600px) {
    .show-on-small {
        display: block !important;
    }
}

@media only screen and (min-width: 601px) {
    .show-on-medium-and-up {
        display: block !important;
    }
}

@media only screen and (max-width: 992px) {
    .show-on-medium-and-down {
        display: block !important;
    }
}

@media only screen and (max-width: 600px) {
    .center-on-small-only {
        text-align: center;
    }
}
