:root {
    .mat-mdc-paginator {
        &:not(.enable-range) {
            .mat-mdc-paginator-range-label {
                display: none;
            }
        }

        &:not(.show-label) {
            .mat-mdc-paginator-page-size-label {
                display: none;
            }
        }

        &.hide-page {
            .mat-mdc-paginator-page-size-label ~ div {
                display: none;
            }
        }

        .mat-mdc-paginator-outer-container {
            width: 100%;
        }

        .mat-mdc-paginator-container {
            flex-wrap: wrap-reverse;
            padding: 0;
        }

        .mat-mdc-paginator-range-label {
            margin: 0 var(--size-default) 0 0;
        }
        .mat-mdc-paginator-page-size-select {
            width: 40px;
            margin-left: 0;
            margin-right: 0;

            .mat-mdc-form-field-flex {
                padding: 0;
            }
        }
        .mat-mdc-paginator-icon {
            fill: mat-color($primary);
        }
        .mat-mdc-paginator-page-size-select{
            .mat-mdc-notch-piece {
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border-bottom-left-radius: unset;
                border-bottom-right-radius: unset;
            }
        }

    }
    .cdk-overlay-pane .mat-mdc-select-panel.mat-paginator-overlay {
        .mat-mdc-option {
            min-height: unset;
            font-size: 12px;
            padding: 0;

            .mdc-list-item__primary-text {
                padding: var(--size-small);
            }
        }
    }
}
