:root {
    mat-slide-toggle {

        .mdc-switch--selected .mdc-switch__handle::after, .mdc-switch__track::after {
            background: mat-color($accent) !important;
        }

        .mdc-switch--unselected:not(.mdc-switch--disabled) .mdc-switch__handle::after {
            background: white !important;
        }

        display: flex;
        align-items: center;
        min-height: 36px;

        .mdc-label {
            max-width: 100%;
        }

        &.text-wrap {
            .mdc-label {
                white-space: pre-wrap;
            }
        }
    }
}
