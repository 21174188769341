:root {
    .mat-mdc-tab-link {
        text-transform: uppercase;
        min-width: initial;
        padding: 0 var(--size-default);

        &.mat-tab-label-active,  &.mat-tab-label-active .mdc-tab__text-label{
            color: mat-color($primary);
            opacity: 1;
        }
    }

    .mat-mdc-tab-link-container, .mat-mdc-tab-label-container {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .mat-mdc-tab-nav-bar {
        .mat-tab-header-pagination {
            &.mat-tab-header-pagination-disabled {
                position: absolute;
                visibility: hidden;
            }
        }

        &[color='light'], &.mat-light {
            background-color: mat-color($primary, '500-contrast');
            color: var(--color-dark);
        }
    }

    .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
        flex-grow: 0;
    }

    .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
        flex-grow: 0;
    }
}
