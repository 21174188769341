:root {
    mat-icon {
        @for $i from 2 through 5 {
            &.zoom-#{$i} {
                width: auto;
                height: auto;
                font-size: calc(26px * #{$i});
            }
        }
    }
}
