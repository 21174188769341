@import 'variables';

full-calendar {
    @for $i from 1 to 31 {
        &.monthly days-display-#{$i} {
            .fc-timeline {
                max-width: calc(200px + (22px * #{$i}) + 10px);
            }
        }
    }

    &.fc {
        display: flex;
        flex-direction: column;
        font-size: 16px;

        @media #{$medium-and-down} {
            font-size: var(--font-size);
        }

        @media #{$small-and-down} {
            font-size: 12px;
        }

        .fc-toolbar.fc-header-toolbar {
            margin: var(--size-default);

            .fc-toolbar-title {
                font-size: inherit;
            }
        }

        .fc-timegrid-cols > table, table.fc-col-header {
            border-spacing: 2px 0;
            border-collapse: initial !important;
        }

        .fc-timegrid-col {
            border: 0;

            &.fc-day-today {
                background: transparent;
            }
        }

        // Remove border
        th, .fc-scrollgrid, .fc-timegrid-slot-label {
            border-color: transparent;
            border-right-width: 0;
            border-left-width: 0;
        }

        td {
            border-style: dashed;
            border-right-width: 0;
            border-bottom-width: 0;
            border-left-width: 0;
        }

        .fc-scrollgrid-section-sticky > * {
            background: transparent;
        }

        .fc-scrollgrid-section-liquid {
            height: 1px;
        }

        // Slot time
        .fc-timegrid-slot-label {
            vertical-align: top;

            .fc-timegrid-slot-label-cushion.fc-timegrid-slot-label-cushion {
                display: flex;
                white-space: nowrap;
                justify-content: flex-end;
                width: 3rem;
                line-height: 1;
                padding: 0;

                > span {

                    &.hour {
                        //line-height: 2.25;
                    }

                    &.min {
                        opacity: .5;
                        font-size: 75%;
                        margin: 0 4px;
                    }
                }
            }
        }

        // Row height
        .fc-timegrid-slot {
            height: 32px;
            line-height: 32px;
        }

        // Background events extra properties
        .fc-bg-event {
            background: none;

            &.heal-absent {
                background: repeating-linear-gradient(45deg, rgba(0,0, 0, .2), rgba(0,0, 0, .2) 2px, transparent 2px, transparent 10px);
                background-color: #fff !important;
                opacity: 1;
                z-index: 3;
            }
        }

        // Now indicator
        .fc-timegrid-now-indicator {
            &-line {
                border-color: mat-color($accent);
                border-top-width: 2px;
            }

            &-arrow {
                border-right-color: mat-color($accent);
                border-left-color: mat-color($accent);
                border-width: 6px 0 6px 7px;
            }
        }

        // Grid header
        .fc-col-header,
        .fc-timeline-header-row {
            font-size: 12px;
            font-weight: 400;

            &.fc-timeline-header-row-chrono {
                th {
                    &.fc-timeline-slot-label {
                        &.fc-day-today {
                            background-color: mat-color($primary);
                            border-radius: 2px;
                            color: mat-color($primary, '500-contrast');
                        }
                    }
                }
            }

            th {
                font-size: inherit;
                font-weight: inherit;
                vertical-align: middle;
                padding: 0 0 1px 0;

                &:not(.fc-timeline-slot-label) {
                    height: var(--size-large);
                }

                &.fc-timeline-slot-label {
                    &.fc-day-today {
                        color: mat-color($primary);
                    }
                }

                .fc-timeline-slot-cushion,
                .fc-col-header-cell-cushion {
                    display: flex;
                    justify-content: center;
                    padding: 0;
                }

                .fc-timeline-slot-frame {
                    justify-content: center;
                }

                &.fc-col-header-cell.fc-resource {
                    position: relative;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    height: var(--size-medium);
                    cursor: default;
                    overflow: visible;

                    .badge-resource {
                        display: block;
                        @include radius(2px);
                        font-size: 10px;
                        color: var(--color-light);
                        text-overflow: ellipsis;
                        width: 100%;
                        height: var(--size-default);
                        line-height: var(--size-default);
                        padding: 0 calc(#{var(--size-small)} / 2);
                        overflow: hidden;
                    }
                }
            }
        }

        // Event
        .fc-timegrid-event {
            position: absolute;
            top: 0;
            bottom: 1px;
            left: 0;
            right: 0;
            box-shadow: -1px 1px 3px rgba(0, 0, 0, .25);
            font-size: 12px;
            cursor: pointer;

            &.extended {
                background-color: transparent !important;

                .fc-event-main {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                }

                .fc-event-main-frame {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
            }
        }

        .fc-timegrid-event,
        .fc-timegrid-col-events {
            margin: 0 1px;
        }

        .fc-event-main-frame, .fc-event-main-frame > * {
            overflow: hidden;

            &:after {
                content: '';
            }
        }

        // Month view
        .fc-resource-timeline {
            padding-top: var(--size-default);

            .fc-datagrid-cell.fc-resource {
                .fc-datagrid-cell-main {
                    display: block;
                    text-overflow: ellipsis;
                    line-height: 1.75;
                    overflow: hidden;
                }
            }

            .fc-resource {
                border-color: transparent;
                font-size: 90%;

                @media #{$small-and-down} {
                    font-size: 100%;
                }
            }

            .fc-datagrid-cell-cushion {
                padding: 0 0 0 var(--size-default);
            }

            .resourceAreaWidth {
                min-height: auto;
            }

            .fc-cell-shaded {
                background: none;
            }

            .fc-timeline-event {
                border-radius: 2px;
            }
        }
    }


    &.monthly {
        height: calc(100% - calc(16px * 10));

        @for $i from 1 through 50 {
            &.resource-display-#{$i} {
                height: calc(26px * #{$i} + (16px * 4));
            }
        }

        .fc-event {
            min-height: 25px !important;
        }
    }

    td {
        font-weight: normal;
    }
}

.calendar {
    @media #{$medium-and-up} {
        &-header {
            h2.month-padding {
                margin-left: 200px;
            }
        }
    }

    &-subtitle {
        color: rgba(mat-color($primary, '50-contrast'), .5);
        font-size: 12px;
    }
}

.calendar-footer {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    max-width: calc(100% - (var(--size-default) * 2));
    right: var(--size-default);
    bottom: var(--size-default);
    left: var(--size-default);

    .color-picker {
        display: flex;
        flex-grow: 1;
        align-items: center;

        mat-icon {
            border-radius: 4px;
        }
    }

    ~ full-calendar {
        margin-bottom: calc((var(--size-default) * 2) + var(--header-height));
    }
}

.fc-nonbusiness {
    background: white;
}

%tooltip-after {
    &:after {
        position: absolute;
        display: flex;
        content: attr(data-title);
        background: mat-color($primary, '500-contrast');
        border-radius: 4px;
        font-size: 12px;
        color: mat-color($primary);
        height: calc(var(--size-default) + var(--size-small));
        padding: 0 var(--size-small);
        align-items: center;
        right: 0;
        top: calc(-1 * (var(--size-default) * 2) + 4px);
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    }
}

%tooltip-element {
    &:before, &:after {
        position: absolute;
        content: '';
        border: 5px solid transparent;
        border-top-color: rgba(0, 0, 0, 0.3);
        border-bottom: 0;
        width: 0;
        height: 0;
        margin-right: 2px;
        top: calc(-1 * var(--size-small) + 4px);
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &:after {
        border-top-color: mat-color($primary, '500-contrast');
        border-width: 4px;
        margin-right: 3px;
    }
}
