// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 1024px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 1023px !default;
$large-screen: 1200px !default;
$smallest-screen: 380px !default;

$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$large-only: "only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen-up})" !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$smallest-and-up: "only screen and (min-width : #{$smallest-screen})" !default;
$smallest-and-down: "only screen and (max-width : #{$smallest-screen})" !default;
