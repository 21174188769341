:root {
    [mat-button], [mat-raised-button], [mat-stroked-button], [mat-flat-button] {
        @extend %mat-button;
        font-weight: 500;

        &.disabled {
            @extend %mat-button-disabled;
        }

        &.block {
            width: 100%;
        }

        &.mat-mdc-icon-button, &.mat-mdc-fab, &.mat-mdc-mini-fab {
            border-radius: 50%;
        }

        mat-icon {
            margin: 0;
            font-size: 24px;
            width: 24px;
            height: 24px;
        }
    }

    [mat-fab] {
        &.sticky {
            &.inside {
                position: sticky;
            }

            &:not(.inside) {
                position: absolute;
            }

            &.top {
                bottom: var(--size-default);
            }

            &.right {
                right: var(--size-default);
            }

            &.bottom {
                bottom: var(--size-default);
            }

            &.left {
                left: var(--size-default);
            }
        }
    }

    [mat-icon-button] {
        background-color: transparent;
        border-color: transparent;

        &:focus {
            outline: none;
        }

        mat-icon {
            color: inherit;
        }

        &.auto {
            width: auto;
            height: auto;
            padding: 0
        }
    }

    .mat-mdc-flat-button {
        &.mat-accent {
            background: mat-color($accent);
            color: inherit;
        }

        &.mat-primary {
            background: mat-color($primary);
            color: inherit;
        }

        &.mat-warn {
            background: mat-color($warn);
            color: inherit;
        }
    }

    //[mat-stroked-button]:not([disabled]):not(.mat-primary):not(.mat-accent) {
    //    border-color: rgba(var(--color-dark), .12);
    //}

    button {
        &[mat-icon-button] {
            .mat-mdc-button-persistent-ripple {
                display: none;
            }
        }

        &.mat-valid {
            background-color: var(--color-valid);
            color: mat-color($primary, '500-contrast');
        }
    }

    mat-button-toggle-group {
        flex-shrink: 1;
        margin-right: 0;
        margin-left: 0;

        mat-button-toggle {
            .mat-button-toggle-label-content {
                padding: 0 var(--size-small);
            }
        }
    }
}
