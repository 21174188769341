@import '_global/media_query';

@mixin cdk-overlay-full-screen() {
    position: absolute !important;
    width: 100vw !important;
    height: 100vh;
    max-width: 100vw !important;
    max-height: 100vh;
    margin: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0;
    left: 0;
    transform: none !important;

    > *:first-of-type {
        justify-content: flex-start;
        width: 100%;
        max-height: 100vh;
    }
}

:root {
    .cdk-overlay-container {
        .cdk-overlay-pane {
            &[style*='max-width:'] {
                mat-dialog-container.mat-mdc-dialog-container {
                    width: auto;
                }
            }

            &[style*='max-width:']:not([style*='max-width: 80vw']) {
                margin: auto !important;

                mat-dialog-container.mat-mdc-dialog-container {
                    width: max-content;
                    max-width: initial;
                    margin: auto;
                    overflow: hidden;
                    overflow-y: auto;

                    form {
                        max-width: 100%;
                    }
                }
            }

            &.inherit-width {
                .mat-mdc-dialog-container.mat-dialog-container {
                    max-width: 100%;
                }
            }

            &.sidenav-right {
                height: 100vh;
                max-width: 300px !important;
                padding: 0;
                @keyframes aside-right-animate {
                    0% {
                        transform: translate(100%, 0);
                    }
                    100% {
                        transform: translate(0, 0);
                    }
                }
                animation: aside-right-animate 0.82s cubic-bezier(.36, .07, .19, .97) both;

                mat-dialog-container.mat-mdc-dialog-container {
                    border-radius: 0;
                    max-height: 100vh;
                }
            }

            @media #{$medium-and-down} {
                &.full-screen-M {
                    @include cdk-overlay-full-screen;
                }
            }

            @media #{$small-and-down} {
                &.full-screen-S {
                    @include cdk-overlay-full-screen;
                }
            }
        }
    }
}

mat-dialog-container {
    &.mat-mdc-dialog-container {
        position: relative;
        width: calc(100vw - (var(--size-default) * 2));
        max-width: 800px;
        max-height: calc(100vh - (var(--size-large) * 2));
        padding: 0;

        .mat-mdc-dialog-content {
            color: var(--color-dark);
        }

        @media #{$medium-and-up} {
            .mat-mdc-dialog-surface {
                padding-right: var(--size-default);
                padding-left: var(--size-default);
            }
        }

        @supports (-webkit-overflow-scrolling: touch) {
            max-height: calc(100vh - (var(--size-large) * 3));
        }

        > *:first-child {
            display: block;
            padding-bottom: 0;
        }

        .mat-mdc-dialog-title {
            padding: 0;
            text-overflow: ellipsis;
            line-height: 20px;
            font-size: 20px;
            font-weight: 500;
            @media #{$medium-and-up} {
                padding-top: var(--size-default);
            }
            margin: var(--size-default);
            overflow: hidden;

            > div[class^="mat-display-"] {
                display: flex;
                margin: 0;
            }

            ~ .mat-mdc-dialog-content {
                margin-top: 0;
                padding-bottom: 0;
            }
        }

        [mat-button], [mat-raised-button], [mat-stroked-button], [mat-flat-button] {
            @extend %mat-button;
        }

        .close {
            position: absolute;
            background: none;
            border: 0;
            font-size: 1.8em;
            line-height: 30px;
            height: 30px;
            width: 30px;
            min-width: 30px;
            padding: 0;
            top: 16px;
            right: 16px;
            cursor: pointer;
            z-index: 1;

            .mat-icon {
                vertical-align: initial;
                height: 30px;
                width: 30px;
            }
        }

        .mat-mdc-dialog-content {
            display: block;
            max-height: max-content;
            padding-right: var(--size-default);
            padding-left: var(--size-default);
            margin: 0;
            margin-top: var(--size-large);
            overflow: hidden;

            &:first-child {
                margin-top: var(--size-medium);
            }

            &.fix-padding {
                margin: 0 calc(var(--size-default) * -1);
            }

            mat-input-container[class*="textarea"] {
                width: 100%;
                resize: none;
            }

            textarea.mat-mdc-input-element {
                resize: none;
            }

            iframe {
                border: 0;
                width: 100%;
                min-height: 200px;
                height: calc(100vh - (48px * 8));
            }

            img {
                display: block;
                max-width: 100%;
                margin: auto;
            }

            .image-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                overflow: auto;

                @media #{$medium-and-up} {
                    height: 50vh;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                    cursor: pointer;
                }
            }
        }

        mat-dialog-actions {
            padding: var(--size-default);

            &[text-right], &[align='right'] {
                justify-content: flex-end;
            }

            &[text-center], &[align='center'] {
                justify-content: center;
            }

            &[text-left], &[align='left'] {
                justify-content: flex-start;
            }

            &[align="space-between"] {
                justify-content: space-between;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .mat-mdc-button.mat-button {
                &[color='grey'] {
                    color: rgba(0, 0, 0, .5);
                }
            }

            @media #{$small-and-down} {
                flex-direction: column;

                .mat-mdc-button.mat-button,
                .mat-mdc-unelevated-button[mat-flat-button] {
                    width: 100%;
                    margin: var(--size-small) auto;
                }

                .mat-mdc-button-base + .mat-button-base {
                    margin-left: auto;
                }

                [emptySpace], //deprecated
                .emptySpace,
                .element-spacer {
                    display: none;
                }
            }
        }
    }
}

.no-padding {
    mat-dialog-container.mat-mdc-dialog-container {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
    }
}
.full-height {
    mat-dialog-container.mat-mdc-dialog-container {
        max-height: 100%;
    }
}
.no-horizontal-padding {
    mat-dialog-container.mat-mdc-dialog-container {
        padding-right: 0;
        padding-left: 0;
    }
}

.image-helper {
    .mat-mdc-dialog-content.mat-dialog-content {
        display: flex;
        flex-direction: column;
    }
}

.invalid-controls {
    .mat-mdc-dialog-content {
        @extend %word-break;
    }
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
