@use '@angular/material' as mat;

@include mat.core();
@import '_material-design/mat-theme-colors';

/* https://github.com/angular/material2/blob/master/guides/typography.md */
$custom-typography: mat.m2-define-typography-config(
        $font-family: 'Roboto, "Helvetica Neue", sans-serif',
);


$heal-theme: mat.m2-define-light-theme((color: (
        primary: mat.m2-define-palette($mat-heal-primary),
        accent: mat.m2-define-palette($mat-heal-accent, 500, 900, A100),
        warn: mat.m2-define-palette($mat-heal-warn)),
        typography: ($custom-typography))
);

html {
    @include mat.all-component-themes($heal-theme);
}
