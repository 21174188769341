@import 'media_query';

@mixin mat-typography-level($font-size, $line-height: $font-size, $font-weight: 400, $font-family: null, $letter-spacing: null) {
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    font-family: $font-family;
    letter-spacing: $letter-spacing;
}

%h1 {
    font-size: 20px;
    font-weight: 500;
}

%h2 {
    font-size: 16px;
    font-weight: 500;
}

%link {
    cursor: pointer;
}

%disabled-color {
    color: rgba(0, 0, 0, 0.26);
}

%disabled-cursor {
    cursor: default;
}

%mat-sidenav-minimize {
    @media #{$medium-and-up} {
        --sidenav-min-width: 64px;

        min-width: var(--sidenav-min-width);
        overflow: visible;
        visibility: visible !important;
        display: block;
        transform: translate3d(calc(-100% + 64px), 0, 0);

        &.mat-drawer-opened {
            .hide-on-open {
                display: none;
            }
        }

        &:not(.mat-drawer-opened) {
            float: right;
            max-width: var(--sidenav-min-width);

            //transition-duration: 400ms;
            //transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
            //transition-property: width;

            ~ .mat-sidenav-content {
                left: 64px;
                width: calc(100% - 64px);
            }

            .hide-on-close {
                display: none;
            }
        }

        .mat-mdc-mini-fab {
            position: absolute;
            transform: scale(.8);
            right: -20px;

            &[color='light'] {
                mat-icon {
                    color: rgba(mat-color($primary, 'A100-contrast'), .75);
                }
            }
        }
    }
}

%template-footer {
    @extend %mat-body-2 !optional;
    display: flex;
    align-items: center;
    position: sticky;
    width: 100%;
    height: var(--footer-height);
    min-height: var(--footer-height);
    box-sizing: border-box;
    top: 100%;
    bottom: 0;

    .copyright {
        @extend .text-trim !optional;
    }
}

%flex-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

%material-icons {
    font-family: 'Material Icons', sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

%mat-icon-disabled {
    @extend %disabled-color;
}

%mat-button-disabled {
    @extend %disabled-color;
    background-color: rgba(0, 0, 0, 0.12);
    @extend %disabled-cursor;
}

%mat-select-button {
    font-size: 15px;
    border-radius: 32px;
    height: 1.2em;
    width: auto;
    padding: .2em 0;
    margin: 8px 0;

    .mat-mdc-select-trigger {
        width: 100%;
    }

    .mat-mdc-select-value {
        color: inherit;
        max-width: none;
        padding-left: 8px;

        .mat-mdc-select-placeholder {
            color: inherit;
        }
    }

    .mat-mdc-select-arrow-wrapper {
        color: inherit;

        .mat-mdc-select-arrow {
            color: inherit;
        }
    }
}

%mat-select-arrow-prefix {
    .mat-mdc-select-value {
        padding-right: 16px;
        padding-left: 32px;
    }

    .mat-mdc-select-arrow-wrapper {
        position: absolute;
        font-size: 1.25em;
        top: 0;
        left: 0;
        margin: 0 0 0 .25em;

        .mat-mdc-select-arrow {
            display: flex;
            width: 1em;
            height: 1em;
            margin: 0 .2em;
        }
    }
}

%heal-admin-search {
    form {
        display: flex;
        align-items: center;

        mat-form-field {
            flex: 1;
            max-width: calc(100vw - 48px - 40px);
        }
    }
}

%heal-search-small {
    mat-form-field {
        &.mat-form-field-appearance-outline {
            .mat-mdc-form-field-flex {
                align-items: center;
                margin-top: 0;
            }

            .mdc-notched-outline {
                z-index: 0;
            }

            .mat-mdc-notch-piece {
                background: #fff;
            }

        }

        .mat-mdc-form-field-infix {
            padding: .55em 0;
            min-height: unset;
            margin: 0;
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }

        .mat-icon {
            padding: 0;
        }
    }
}

%heal-form-loader {
    position: absolute;
    background: rgba(255, 255, 255, .75);
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;

    &.blur-below ~ *:not(.mat-toolbar),
    &.blur-below.blur-all ~ * {
        @include blur(1.5px);
    }
}


%heal-new-loader {
    position: sticky;
    width: 100%;
    height: 2px;
    min-height: 2px;
    margin-top: -2px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;

    ~ .blur-form-element, ~ * .blur-form-element,
        // Deprecated
    ~ [blur-form-element], ~ * [blur-form-element] {
        position: relative;
        @extend %heal-blur;
        pointer-events: none;

        &:after {
            position: absolute;
            background: rgba(255, 255, 255, .5);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    ~ .blur-element, ~ * .blur-element,
        // Deprecated
    ~ [blur-element], ~ * [blur-element] {
        @extend %heal-blur;
        pointer-events: none;
    }
}

%heal-blur {
    @include blur(1.5px);
}


// --------------------------------------------------
// Clear
// --------------------------------------------------
%clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


// --------------------------------------------------
// text-wrap
// --------------------------------------------------
%text-wrap {
    white-space: normal;
    word-break: normal;
    word-wrap: break-word;
}


// --------------------------------------------------
// word-break
// --------------------------------------------------
%word-break {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
}


// --------------------------------------------------
// height-auto
// --------------------------------------------------
%height-auto {
    height: auto;
}


// --------------------------------------------------
// Used to extend some material design inheritance
// _mixin.scss
// --------------------------------------------------
%mat-display-4 {
    @include mat-typography-level(96px, 96px, 300, $letter-spacing: - 1.5px)
}

%mat-display-3 {
    @include mat-typography-level(60px, 60px, 300, $letter-spacing: -0.5px)
}

%mat-display-2 {
    @include mat-typography-level(48px, 48px, 400, $letter-spacing: 0px)
}

%mat-display-1 {
    @include mat-typography-level(34px, 34px, 400, $letter-spacing: .25px)
}

%mat-headline {
    @include mat-typography-level(24px, 32px, 400, $letter-spacing: 0px)
}

%mat-title {
    @include mat-typography-level(20px, 20px, 500, $letter-spacing: .25px)
}

%mat-subheading-2 {
    @include mat-typography-level(16px, 24px, 400, $letter-spacing: .15px)
}

%mat-subheading-1 {
    @include mat-typography-level(14px, 24px, 500, $letter-spacing: .15px)
}

%mat-body-2 {
    @include mat-typography-level(14px, 20px, 400, $letter-spacing: .25px)
}

%mat-body-1 {
    @include mat-typography-level(16px, 28px, 400, $letter-spacing: .5px)
}

%mat-caption {
    @include mat-typography-level(12px, 16px, 400, $letter-spacing: .4px)
}

%mat-button {
    @include mat-typography-level(14px, 36px, 500, $letter-spacing: .25px);

    border-radius: var(--radius);
    text-transform: uppercase;
    max-width: 100%;

    &:not(.multi-line) {
        mat-button {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

%mat-input {
    @include mat-typography-level(inherit, 1.125, 400, $letter-spacing: .25px)
}
