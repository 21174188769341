@import '_global/extend';

:root {
    mat-card {
        padding: var(--size-default);

        mat-card-header {
            padding: 0;

            .mat-card-header-text {
                margin: 0;
            }
        }

        mat-card-content {
            padding: 0;
        }

        .mat-mdc-card-subtitle {
            margin-bottom: 16px;
            @extend %mat-body-2 !optional;
        }

        mat-card-actions {
            &[class*='flex-'] {
                display: flex;
            }
        }

        .mat-divider-horizontal {
            &.margin-horizontal {
                left: var(--size-default);
                width: calc(100% - (var(--size-default) * 2));
            }
        }
    }
}
