/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'utils';

@import 'mat-core-theme';
@import 'mat-custom';

@import 'fonts';

@import 'gp-global';
@import 'gp-layout';

@import 'gp-resource';

@import 'custom-materialize';
@import 'custom-toastr';

// TODO: check them later
@import './style/components';
@import './style/fullcalendar-custom';
@import './style/html-code';
@import './style/resource-color';
