html, body {
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 400;
    min-width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

gp-root {
    position: relative;
    display: block;
    width: 100%;
}

form {
    &.hidden {
        visibility: hidden;
    }
}

.hide {
    display: none !important;
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

footer {
    button.mat-mdc-icon-button.add {
        z-index: 1;
    }
}
