:root {
    .mat-toolbar {
        flex-shrink: 0;

        &:not([color]) {
            background-color: transparent;
        }

        &[color='light'], &.mat-light {
            background-color: mat-color($primary, '500-contrast');
            color: var(--color-dark);
        }

        &[color='grey'], &.mat-grey {
            background-color: mat-color($primary, 50);
            color: var(--color-dark);
        }

        &[color='grey-dark'], &.mat-grey-dark {
            background-color: mix(mat-color($primary, 900), mat-color($primary, '500-contrast'), 15%);
            color: var(--color-dark);
        }

        &[color='dark'], &.mat-dark {
            background-color: rgba(var(--color-midgrey-rgb), .5);
            color: var(--color-light);

            * {
                color: inherit;
            }
        }

        h2 {
            @extend %h2;
        }

        > {
            mat-checkbox,
            mat-slide-toggle {
                @extend %mat-body-2;
                display: flex;
                align-items: center;
            }
        }

        .small {
            font-size: 65%;
            line-height: 1.25;
        }
    }
}
