:root {
    .resource-chip {
        mat-icon {
            border-radius: 50%;
            min-width: var(--size-default);
            width: var(--size-default);
            height: var(--size-default);
            margin-right: var(--size-small);
        }

        label {
            width: 100%;
            max-width: 10em;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
