:root {
    .cdk-overlay-container {
        .mat-mdc-select-panel {
            padding: 0;
        }

        .mat-mdc-menu-content {
            padding: 0;

            .mat-mdc-selection-list {
                ~ [mat-raised-button] {
                    border-radius: 4px;
                }
            }
        }

        .mat-mdc-menu-item {
            &[color] {
                .mat-icon-no-color {
                    color: inherit;
                }
            }

            &:not([disabled]) {
                &[color="primary"] {
                    color: mat-color($primary);
                }

                &[color="accent"] {
                    color: mat-color($accent);
                }

                &[color="warn"] {
                    color: mat-color($warn);
                }
            }
        }

        .mat-mdc-nav-list {
            .mat-mdc-list-item {
                &.header-group {
                    font-size: 75%;
                    opacity: .75;
                    cursor: default;

                    &:hover {
                        background: none;
                    }

                    .mdc-list-item__content {
                        padding: var(--size-default);
                    }
                }

                .red-dot {
                    position: absolute;
                    left: calc(100% - 16px);
                    height: 5px;
                    width: 5px;
                    background-color: mat-color($warn);
                    border-radius: 50%;
                }
            }
        }
    }
}
