:root {
    [cdkDropListGroup] {
        display: flex;
        flex-wrap: wrap;

        .drop-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .drop-item {
            border-radius: 4px;
            padding: 20px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            cursor: move;
        }

        .cdk-drag-preview {
            box-sizing: border-box;
            border-radius: 4px;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .cdk-drag-placeholder {
            opacity: 0;
        }

        .cdk-drag-animating {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }

        .mat-mdc-chip {
            @media #{$medium-and-down} {
                word-break: break-word;
                height: auto;
            }
        }
    }
}
