@import 'variables';

$size-map: s S m M l L;
$space-name-map: padding margin;
$space-position-map: top right bottom left;

$rotate-map: 45 90 135 180 225 270 315;

$layout-size: (
        S: 8px,
        default: 16px,
        M: 24px,
        L: 32px
);

:root {
    @each $position in $space-position-map {
        .border-#{$position} {
            border-#{$position}: 1px solid var(--color-grey);
        }

        @each $size in $size-map {
            @if $size == 'S' or $size == 's' {
                @media #{$small-and-down} {
                    .border-#{$position}-#{$size} {
                        border-#{$position}: 1px solid var(--color-grey);
                    }
                }
            } @else if $size == 'M' or $size == 'm' {
                @media #{$medium-only} {
                    .border-#{$position}-#{$size} {
                        border-#{$position}: 1px solid var(--color-grey);
                    }
                }
            } @else if $size == 'L' or $size == 'l' {
                @media #{$large-and-up} {
                    .border-#{$position}-#{$size} {
                        border-#{$position}: 1px solid var(--color-grey);
                    }
                }
            }

        }
    }

    @each $space-name in $space-name-map {
        .#{$space-name} {
            #{$space-name}: var(--size-default);
        }

        .no-#{$space-name} {
            #{$space-name}: 0;
        }

        @each $space-position in $space-position-map {
            .#{$space-name}-#{$space-position} {
                #{$space-name}-#{$space-position}: var(--size-default);
            }
        }

        .#{$space-name}-vertical {
            #{$space-name}-top: var(--size-default);
            #{$space-name}-bottom: var(--size-default);
        }

        .#{$space-name}-horizontal {
            #{$space-name}-right: var(--size-default);
            #{$space-name}-left: var(--size-default);
        }

        @each $size in $size-map {
            .#{$space-name}-vertical-#{$size} {
                #{$space-name}-top: map_get($layout-size, #{$size});
                #{$space-name}-bottom: map_get($layout-size, #{$size});
            }

            .#{$space-name}-horizontal-#{$size} {
                #{$space-name}-right: map_get($layout-size, #{$size});
                #{$space-name}-left: map_get($layout-size, #{$size});
            }

            @each $space-position in $space-position-map {
                .#{$space-name}-#{$size} {
                    #{$space-name}: map_get($layout-size, #{$size});
                }

                .#{$space-name}-#{$space-position}-#{$size} {
                    #{$space-name}-#{$space-position}: map_get($layout-size, #{$size});
                }
            }
        }
    }

    // Rotate
    @each $rotate in $rotate-map {
        .rotate-#{$rotate} {
            position: relative;
            transform: rotate(#{$rotate}deg);

            &.flip {
                transform: rotate(#{$rotate}deg) scaleX(-1);
            }
        }
    }

    // Strong
    @each $size in $size-map {
        @if $size == 'S' or $size == 's' {
            @media #{$smallest-and-up} {
                .only-margin-top-#{$size} {
                    margin-top: var(--size-default);
                }
            }
            @media #{$small-and-down} {
                .strong-#{$size} {
                    font-weight: 600;
                }
            }
        } @else if $size == 'M' or $size == 'm' {
            @media #{$medium-and-up} {
                .only-margin-top-#{$size} {
                    margin-top: var(--size-default);
                }
            }
            @media #{$medium-and-down} {
                .strong-#{$size} {
                    font-weight: 600;
                }
            }
        } @else if $size == 'L' or $size == 'l' {
            @media #{$large-and-up} {
                .only-margin-top-#{$size} {
                    margin-top: var(--size-default);
                }
            }
            @media #{$large-and-up} {
                .strong-#{$size} {
                    font-weight: 600;
                }
            }

        } @else if $size == 'XL' or $size == 'xl' {
            @media #{$large-and-up} {
                .only-margin-top-#{$size} {
                    margin-top: var(--size-default);
                }
                .strong-#{$size} {
                    font-weight: 600;
                }
            }
            @media #{$large-and-up} {
                .strong-L {
                    font-weight: 400;
                }
            }
        }

    }

    @each $opacity in 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 {
        .opacity-#{$opacity} {
            opacity: calc(#{$opacity} / 10);
        }
    }
}

.margin-auto {
    margin: auto;
}

.sticky {
    position: sticky;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-start {
    display: flex;
    align-items: flex-start;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-right {
    text-align: right;

    @media #{$medium-and-down} {
        &-m, &-M {
            text-align: right !important;
        }
    }

    @media #{$small-and-down} {
        &-s, &-S {
            text-align: right !important;
        }
    }
}

.text-center {
    text-align: center;

    @media #{$medium-and-down} {
        &-m, &-M {
            text-align: center !important;
        }
    }

    @media #{$small-and-down} {
        &-s, &-S {
            text-align: center !important;
        }
    }
}

.text-left {
    text-align: left;

    @media #{$medium-and-down} {
        &-m, &-M {
            text-align: left !important;
        }
    }

    @media #{$small-and-down} {
        &-s, &-S {
            text-align: left !important;
        }
    }
}

.text-wrap {
    @extend %text-wrap;
}

.text-nowrap {
    white-space: nowrap;
}

.text-trim {
    display: initial;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-bold {
    font-weight: bold;
}

.text-uppercase {
    text-transform: uppercase;
}

.preserve-whitespaces {
    white-space: pre-wrap;
}

.text-underline {
    text-decoration: underline;
}

.text-no-underline {
    text-decoration: none;
}

.no-appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-clear-button, &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

.height-auto {
    height: auto;
}

.element-spacer {
    flex: 1 1 auto;
}

.link {
    @extend %link;
}

a.link {
    color: mat-color($primary);
    cursor: pointer;
}

.full-width {
    width: 100%;
}

[heal-header-wrapper] {
    .mat-toolbar {
        heal-admin-search {
            font-size: initial;

            @media #{$small-and-down} {
                background: inherit;
                min-height: 56px;
                width: 100%;
            }

            form {
                @extend %heal-search-small;

                mat-form-field {
                    @media #{$small-and-down} {
                        padding: 0 var(--size-default);
                    }
                }
            }
        }

        heal-logo {
            max-height: 30px; // Android?!
        }

        button.user {
            text-transform: initial;
            max-width: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0 calc(var(--size-default) / 2);
            }
        }
    }
}

div[toastContainer] {
    position: sticky;
    top: 0;
    z-index: 10;
}

[hidden="true"], //deprecated
.hidden {
    visibility: hidden;
}

[color="primary"] {
    color: mat-color($primary);
}

[color="accent"] {
    color: mat-color($accent);
}

[color="warn"] {
    color: mat-color($warn);
}

.x- {
    &50 {
        transform: scale(.5);
    }

    &100 {
        transform: scale(1);
    }

    &150 {
        transform: scale(1.5);
    }

    &200 {
        transform: scale(2);
    }

    &250 {
        transform: scale(2.5);
    }

    &300 {
        transform: scale(3);
    }
}

.emptyScreen {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: mat-color($primary, '500-contrast');
    color: mat-color($warn);
    text-align: center;
    font-size: 16px;
    line-height: 1.7;

    > div {
        max-width: $small-screen;
    }

    .mat-icon {
        position: absolute;
        opacity: .1;
        font-size: 10em;
        margin: -.5em 0 0 -.5em;
        top: 25%;
        left: 50%;
        z-index: 0;
    }
}

.emptySpace {
    flex: 1 1 auto;
}

.displayActions {
    visibility: hidden !important;
    width: 0 !important;
}

[actionsDropdownStyle] { // TODO: verify usage
    margin-right: 20px;
}

[dialogTitle] {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

[heal-color-picker-input-hidden] {
    @include opacity(0);
    z-index: 0;

    ~ heal-color-picker {
        position: absolute;
        display: flex;
        top: -.5em;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

[xSmsSimulate] {
    position: sticky;
    border-radius: 4px;
    background: rgba(mat-color($primary, 50), 1);
    width: calc(100% - (2 * var(--size-default)));
    margin: 0 0 var(--size-default) 0;
    padding: var(--size-default);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14);
    right: var(--size-default);
    bottom: var(--size-default);
    left: var(--size-default);
    z-index: 1;

    &.mat-mdc-slide-toggle {
        min-height: initial;
    }

    &.mat-mdc-slide-toggle-checked {
        background: rgba(mat-color($accent, 50), 1);

        .mdc-label {
            color: mat-color($accent, 700);
        }

        .mdc-form-field {
            flex-grow: 1;
        }
    }
}

mat-dialog-container {
    [xSmsSimulate] {
        width: 100%;
        margin: 0 calc(var(--size-default) * -1);
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clearfix {
    clear: both;
}

.disable-selection {
    pointer-events: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.onboarding-mobile-padding {
    mat-sidenav-container,
    mat-dialog-container.mat-mdc-dialog-container {
        @media #{$small-and-down} {
            padding-bottom: 88px;
        }

    }
}

.fab-mobile-padding {
    @media #{$medium-and-down} {
        padding-bottom: 72px;
    }
}
