@import '_material-design/mat-init';
@import '_global/mixin';
@import '_global/extend';
@import '_global/media_query';

:root {
    // Usage: font-weight: 400
    // Roboto-Thin: 100 | Roboto-Light: 300 | Roboto-Regular: 400 | Roboto-Medium: 500 | Roboto-Bold: 700 | Roboto-Black: 900
    --font-family: Roboto, "Helvetica Neue", sans-serif;
    --font-size: 16px;

    --radius: 8px;

    --header-height: 64px;
    --footer-height: 48px;

    --sidenav-width: 12.5rem;

    --size-small: 8px;
    --size-default: 16px;
    --size-medium: 24px;
    --size-large: 32px;
    --size-S: var(--size-small);
    --size-M: var(--size-medium);
    --size-L: (--size-large);

    --color-light: #fff;
    --color-light-rgb: 255, 255, 255;
    --color-dark: #0f2050;
    --color-dark-rgb: 15, 32, 80;
    --color-grey: #e5e7ef;
    --color-grey-rgb: 229, 231, 239;
    --color-midgrey: #0a143c;
    --color-midgrey-rgb: 10, 20, 60;
    --color-midgrey-inactive: rgba(#0a143c, .54);
    --color-midgrey-active: rgba(#0a143c, .38);
    --color-light-green: #e4faf0;

    // Custom :(
    --color-highlight: #eab20b;
    --color-highlight-rgb: 234, 178, 11;
    --color-valid: #4caf50;
    --color-valid-rgb: 76, 175, 80;
}
