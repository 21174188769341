// --------------------------------------------------
// Transform
// USE: @include transform(prop,time,animation);
// --------------------------------------------------
@mixin transform($prop,$time,$animation) {
	-webkit-transition: $prop $time $animation;
    -moz-transition: $prop $time $animation;
    -o-transition: $prop $time $animation;
	transition: $prop $time $animation;
}

// --------------------------------------------------
// Rotate
// USE: @include rotate(-90deg);
// --------------------------------------------------
@mixin rotate($value) {
    -webkit-transform: rotate($value);
    -moz-transform: rotate($value);
    -ms-transform: rotate($value);
    -o-transform: rotate($value);
}

// --------------------------------------------------
// Opacity
// USE: @include opacity(80);
// --------------------------------------------------
@mixin opacity($value) {
    $opacity-ie: $value * 100;
    $value: $value / 100;
    opacity: $value;
    filter: alpha(opacity=$opacity-ie);
    -khtml-opacity: $value;
    -moz-opacity: $value;
}

// --------------------------------------------------
// Border radius
// USE: @include radius(100%);
// --------------------------------------------------
@mixin radius($v1: null,$v2: null,$v3: null,$v4: null) {
    $value: 0;
    @if ($v1) {
        $value: $v1;
        @if ($v2) {
            $value: $v1 $v2;
            @if ($v3) {
                $value: $v1 $v2 $v3 $v2;
                @if ($v4) {
                    $value: $v1 $v2 $v3 $v4;
                }
            }
        }
    }
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
	border-radius: $value;
}

// --------------------------------------------------
// Filter blur
// USE: @include blur(2px);
// --------------------------------------------------
@mixin blur($value) {
    -webkit-filter: blur($value);
    -moz-filter: blur($value);
    -o-filter: blur($value);
    -ms-filter: blur($value);
    filter: blur($value);
}

// --------------------------------------------------
// Filter blur
// USE: @include hyphens(auto);
// --------------------------------------------------
@mixin hyphens($value) {
    -webkit-hyphens: $value;
    -moz-hyphens: $value;
    hyphens: $value;
}

// --------------------------------------------------
//
// --------------------------------------------------
@mixin border-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

// --------------------------------------------------
// Shake
// USE: @include shake();
// Prebuild: .shake, .shake:before, .shakeafter
// --------------------------------------------------
@mixin shake() {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px
}




// --------------------------------------------------
// Used to extend some material design inheritance
// _extend.scss
// --------------------------------------------------
@mixin mat-typography-level($font-size, $line-height: $font-size, $font-weight: 400, $font-family: null, $letter-spacing: null) {
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    font-family: $font-family;
    letter-spacing: $letter-spacing;
}
