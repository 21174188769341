@import 'variables';

$resource-color-list:
        '#AD1557',
        '#D81C60',
        '#D51700',
        '#E67C73',
        '#F4511D',
        '#EF6C02',
        '#F09301',
        '#F6BF26',
        '#E4C441',
        '#C0CA33',
        '#7CB342',
        '#33B679',
        '#148043',
        '#159688',
        '#039BE5',
        '#4385F4',
        '#4050B5',
        '#7986CB',
        '#B39DDB',
        '#9E69AF',
        '#8E24AA',
        '#795547',
        '#616161',
        '#A79B8E',
        '#ee6e73',
        '#0f2050',
        'DEFAULT_ROOM',
        'DEFAULT_DEVICE',
        'NONE';

@each $color in $resource-color-list {
    .badge-resource[resource-color='#{$color}'],
    .resource-chip .mat-icon[resource-color='#{$color}']{
        @if $color == 'DEFAULT_ROOM' {
            background: mat-color($primary, '50-contrast');
        } @else {
            background: #{$color};
        }
    }
    .mat-mdc-list-option[resource-color='#{$color}'],
    .mat-mdc-option[resource-color='#{$color}'] {

        &:not(.mdc-list-item--disabled), &:not(.mdc-list-item--disabled):hover {

            .mat-pseudo-checkbox, .mdc-list-item__start.mat-mdc-list-option-checkbox-before .mdc-checkbox__background {
                @if $color == 'DEFAULT_ROOM' {
                    border-color: mat-color($primary, '50-contrast');
                } @else {
                    border-color: #{$color};
                }
            }

            .mat-pseudo-checkbox-checked, &[aria-selected="true"] .mdc-list-item__start.mat-mdc-list-option-checkbox-before .mdc-checkbox__background {
                @if $color == 'DEFAULT_ROOM' {
                    background-color: mat-color($primary, '50-contrast');
                } @else {
                    background: #{$color};
                }
            }
        }
    }


    .mat-mdc-radio-button[resource-color='#{$color}'] {
        &:not(.mdc-list-item--disabled) {
            &.mat-mdc-radio-checked {
                .mdc-radio {
                    .mdc-radio__outer-circle {
                        @if $color == 'DEFAULT_ROOM' {
                            border-color: mat-color($primary, '50-contrast') !important;
                        } @else {
                            border-color: #{$color} !important;
                        }
                    }

                    .mdc-radio__inner-circle {
                        @if $color == 'DEFAULT_ROOM' {
                            border-color: mat-color($primary, '50-contrast') !important;
                        } @else {
                            border-color: #{$color} !important;
                        }
                    }
                }
            }
        }
    }

    .mat-mdc-list-option[resource-color='#{$color}'],
    .mat-mdc-radio-button[resource-color='#{$color}'] {

        &.mdc-list-item--disabled {
            background-color: transparent;
            opacity: .5;
        }

        &.disable-selection {
            .mdc-list-item__start, .mdc-list-item__content {
                pointer-events: none !important;
            }
        }
    }
}
