:root {
    .mat-mdc-chip, .mat-mdc-chip.mat-mdc-standard-chip {
        background-color: mat-color($primary, 50);
        word-break: break-word;
        height: fit-content;
        color: rgba(var(--color-dark-rgb), 0.6);
        padding: 7px 12px;
        margin: 4px;

        .mdc-evolution-chip__action {
            padding: 0;
        }

        .mdc-evolution-chip__text-label {
            display: flex;
            align-items: center;
            color: inherit;

        }

        .mdc-evolution-chip__cell {
            > .mat-icon {
                margin-left: 8px;
            }
        }

        .mat-icon {
            color: inherit;
        }

        span {
            &.text-nowrap {
                max-width: 12rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &.link {
            cursor: pointer;
        }

        &:after {
            background: rgba(mat-color($primary, 700), .6);
            color: mat-color($primary);
        }

        &.marked {
            background: mat-color($warn, 50);
            color: mat-color($warn);

            label {
                color: mat-color($primary, '500-contrast');
            }

            mat-icon {
                background: inherit;
                color: inherit;

                &.delete {
                    color: mat-color($warn);
                }
            }
        }

        &:not(.marked) {
            button.mat-mdc-icon-button {
                pointer-events: none !important;
            }
        }

        button.mat-mdc-icon-button {
            width: var(--size-medium);
            height: var(--size-medium);
            line-height: var(--size-medium);
            margin-top: 2px;
            margin-left: calc(var(--size-small) / 2);
            padding: 0;
        }

        &.draggable {
            margin: var(--size-small);
            cursor: pointer;
        }

        &.disabled {
            opacity: .5;
        }

        &.color- {
            &primary {
                background: mat-color($primary);
                color: mat-color($primary, '500-contrast');
            }

            &accent {
                background: mat-color($accent);
                color: mat-color($accent, '500-contrast');
            }

            &warn {
                background: mat-color($warn);
                color: mat-color($warn, '500-contrast');
            }
        }
    }
}
