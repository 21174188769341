:root {
    .mat-slider-horizontal {
        width: 100%;
        height: 24px;
        margin-left: 0;
        margin-right: 0;

        &:not(.mat-slider-thumb-label-showing) {
        }

        .mdc-slider__thumb-knob {
            top: 25%;
        }

        .mat-mdc-focus-indicator {
            display: none;
        }

        ~ .mat-slider-information {

        }

        &.mdc-slider--discrete {
            .mat-slider-thumb-label {
                top: -34px;
            }

            ~ .mat-slider-information {
                > div {
                    &:first-of-type {
                        margin-left: -.5em;
                    }

                    &:last-of-type {
                        margin-right: -.5em;
                    }
                }
            }

            .mdc-slider__value-indicator {
                padding: 0;
            }

            &.suffix-min {
                .mdc-slider__value-indicator::after {
                    position: absolute;
                    content: 'min';
                    font-size: 75%;
                    top: -1.45em;
                    left: 2em;
                    transform: rotate(-45deg) !important;
                    cursor: default;
                }
            }
        }

        ~ .mat-slider-information {
            display: flex;
            justify-content: space-between;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                border-radius: 50%;
                font-size: 75%;
                min-width: 2em;
                min-height: 2em;
                line-height: 1;
                margin-top: var(--size-small);

                &:first-of-type {
                    margin-left: -.5em;
                }

                &:last-of-type {
                    margin-right: -.5em;
                }


                @media #{$small-and-down} {
                    font-size: 62.5%;
                }
            }
        }

        &.mat-primary {
            &.mdc-slider--discrete {
                .mdc-slider__value-indicator {
                    background-color: mat-color($primary) !important;
                }
            }

            ~ .mat-slider-information {
                > div {
                    &.numberCircle {
                        border-color: mat-color($primary);
                    }
                }
            }
        }

        &.mat-accent {
            &.mdc-slider--discrete {
                .mdc-slider__value-indicator {
                    background-color: mat-color($accent) !important;
                }
            }

            ~ .mat-slider-information {
                > div {
                    &.numberCircle {
                        border-color: mat-color($accent);
                    }
                }
            }
        }
    }
}
