@mixin action-wrapper {
    .action-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .mat-mdc-icon-button:not([color]) {
            opacity: .6;
        }
    }
}

@mixin drag-list-item {
    margin: 0;
    overflow: hidden;

    @include list-item-state;

    &:not(.no-padding) {
        padding: var(--size-default) 0;
    }

    .col {
        overflow: hidden;
    }

    &.mat-mdc-list-item {
        display: flex;
        align-items: center;
        height: auto;

        .mdc-list-item__content > :first-child {
            display: flex;
            align-items: center;
        }

        .title-wrapper {
            &.with-icon {
                max-width: calc(100% - 56px);
            }

            .title {
                display: flex;
                align-items: center;

                .title-text.default {
                    max-width: calc(100% - 90px);
                }
            }

            .description {
                display: block;
                font-size: 14px;
                opacity: .6;
            }

            mat-chip {
                padding: 0 var(--size-default);
                min-height: 24px;
                color: mat-color($accent, 500);
                background-color: mat-color($accent, 100);
            }
        }

        @include action-wrapper;

        .text-action-wrapper {
            display: flex;
            flex-shrink: 1;
            justify-content: space-between;
            overflow: hidden;

            @media #{$medium-and-down} {
                justify-content: flex-end;
            }

            .mat-mdc-icon-button:not([color]) {
                opacity: .6;
            }
        }

        [cdkDragHandle] {
            cursor: move;
        }
    }
}

@mixin list-item-state {
    &.disabled {
        .mdc-list-item__content {
            .col:not(.action-wrapper):not(.text-action-wrapper), kds-mat-chip-list {
                opacity: .3;
            }
        }
    }

    &.read {
        .mdc-list-item__content {
            .col:not(.action-wrapper), .col:not(.text-action-wrapper) {
                opacity: .7;
            }
        }
    }

    &.selected {
        background-color: rgba(mat-color($primary, 100), .5);

        &.disabled {
            .mdc-list-item__content {
                .col:not(.action-wrapper), .col:not(.text-action-wrapper) {
                    opacity: 1;
                }
            }
        }
    }
}

@mixin list-item {
    border-bottom: 1px solid var(--color-grey);

    @include list-item-state;

    &.header {
        background: var(--color-light);
        z-index: 1;

        &.sticky {
            position: sticky;
            top: 0;
        }

        .col {
            font-size: 87.5%;
            opacity: .6;
        }
    }

    &.row {
        margin: 0;
        width: 100%;
    }

    .col {
        display: flex;
        align-items: center;
        min-height: 48px;

        mat-icon {
            &.hide-on-large-only {
                font-size: 95%;
                line-height: 1.25;
                height: auto;
                width: auto;
                margin-right: var(--size-small);
                opacity: .3;
            }
        }

        &[text-nowrap], &.text-nowrap {
            display: block;
            line-height: 48px; // Default min-height or row
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    @include action-wrapper;

    @media #{$medium-and-down} {
        height: auto !important;
        min-height: 48px;
        padding: var(--size-default) 0;

        .col {
            &:empty {
                display: none;
            }

            &:not(.action-wrapper) {
                font-size: 85%;
            }

            &.name {
                font-size: 125%;
            }

            &.action-space {
                width: 80%;
            }

            &.small-line {
                min-height: auto;
            }

            &.action-wrapper {
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

:root {
    .mat-mdc-list-base {
        .mdc-list-item__primary-text {
            color: inherit;
            font-size: inherit;

            &[disableripple='true'] {
                cursor: auto;
            }

            @include list-item-state;
        }
    }

    mat-list {
        &.mat-list-drag {
            .mat-mdc-list-item {
                @include drag-list-item;

                &:not(:first-of-type) {
                    border-top: 1px solid var(--color-grey);
                }
            }
        }

        .small {
            font-size: 65%;
        }
    }

    .mat-list-drag-item.cdk-drag-preview {
        @include drag-list-item;
        background-color: var(--color-light);
        height: 48px !important;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .mat-list-responsive {
        &:not(.no-margin) {
            margin: 0 var(--size-default);
        }

        &.disabled {
            opacity: .3;
        }

        .mat-mdc-list-item {
            @include list-item;

            @media #{$medium-and-down} {
                .mdc-list-item__content {
                    display: block;
                }
            }

            .mdc-list-item__content {
                display: block;
            }

            ~ mat-expansion-panel.mat-expanded:last-of-type {
                margin-bottom: var(--size-default);

                ~ mat-divider {
                    visibility: hidden;
                }
            }

            ~ mat-expansion-panel {
                clear: both;
            }
        }
    }

    .mat-list-option-responsive {
        .mat-mdc-list-item {
            @include list-item;

            &.header {
                width: auto;
                padding-left: var(--size-large);
            }

            .mat-mdc-list-option-checkbox-before {
                margin-right: var(--size-small);
            }

            .sortable {
                cursor: pointer;
            }
        }

        .mat-mdc-list-option {
            @include list-item;

            &[aria-selected="true"] {
                background-color: mat-color($primary, 50);
            }

            .row {
                display: flex;
                align-items: center;
                width: 100%;
            }

            @media #{$medium-and-down} {
                .row {
                    display: block;
                }
            }
        }
    }
}
