@import 'cdk-custom';

@import 'mat-custom-accordion';
@import 'mat-custom-button';
@import 'mat-custom-card';
@import 'mat-custom-chip';
@import 'mat-custom-divider';
@import 'mat-custom-error';
@import 'mat-custom-form-field';
@import 'mat-custom-icon';
@import 'mat-custom-list';
@import 'mat-custom-menu';
@import 'mat-custom-modal';
@import 'mat-custom-nav';
@import 'mat-custom-paginator';
@import 'mat-custom-progress-bar';
@import 'mat-custom-slide-toggle';
@import 'mat-custom-slider';
@import 'mat-custom-toolbar';

gp-root {
    mat-icon {
        &.primary {
            color: mat-color($primary);
        }

        &.accent {
            color: mat-color($accent);
        }

        &.warn {
            color: mat-color($warn);
        }

        &.disabled {
            @extend %mat-icon-disabled;
        }

        &[link] {
            cursor: pointer;
        }
    }
}

