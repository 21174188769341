:root {
    mat-accordion {
        &.settings {
            .mat-expansion-panel:not(.mat-expanded) {
                background: none;
                box-shadow: none;

                .mat-expansion-panel-header-title,
                .mat-expansion-indicator::after {
                    color: mat-color($primary);
                }
            }

            .mat-expansion-panel-header[aria-disabled=true] {
                color: rgba(0, 0, 0, 0.87);
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.04);
                }

                .mat-expansion-indicator {
                    position: absolute;
                    left: var(--size-M);
                }

                &.mat-expanded {
                    .mat-expansion-indicator {
                        transform: rotate(180deg);
                    }
                }
            }

            .mat-expansion-panel-header.mat-expanded {
                height: 48px;
            }

            .prevent-click {
                pointer-events: none;
            }

            .subtitle {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0.25px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
}
