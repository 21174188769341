.html-code {
    font-family: Monospace;
    background: rgba(var(--color-grey-rgb), .5);
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    color: initial;
    word-break: break-word;
    padding: var(--size-default);

    .comment {
        color: initial;
        opacity: .5;
    }

    .no-code {
        color: initial;
        opacity: .7;
    }

    .script {
        color: #ff5722;
    }

    .name {
        color: #87000b;
    }

    .attr {
        color: #0D47A1;
    }

    .value {
        color: green;
    }
}
